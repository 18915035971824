<template>
  <div class="indicadores-container">
      <router-view/>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>